<template>
  <div class="company-list hasPagination" v-loading="loading" element-loading-customClass="loading-wrapper">
    <div class="company-list-wrapper">
      <div v-for="(l, i) in data" :key="i" class="item" link="#">
        <CompanyCard :isExam="isExam" :data="l" :sixIndustry="sixIndustry"
          :link="toCompanyDetail + (l.ats_company_id || l.company_id) + (cfId ? `/?cf_id=${cfId}` : '')" />
      </div>
    </div>
    <div class="pagination" v-if="nums && pagination">
      <el-pagination background layout="prev, pager, next" :current-page="page" :total="total" :page-size="nums"
        :hide-on-single-page="!pagination || !total || total < nums" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import CompanyCard from "components/companyCard";
import { toCompanyDetail } from "constants/routerLink";
export default {
  name: "CompanyListWrapper",
  props: {
    nums: Number,
    pagination: Boolean,
    filterData: {
      type: [Object],
      default() {
        return {};
      },
    },
    ajaxApi: {
      type: String,
      default: "getCompanyList",
    },
    cfId: String,
    ids: Array,
    isExam: {
      type: Boolean,
      default: false,
    },
    sixIndustry: Boolean,
  },
  data() {
    return {
      page: 1,
      total: null,
      data: null,
      loading: false,

    }
  },
  computed: {
    toCompanyDetail: () => toCompanyDetail,
  },
  created() {
    this.getData();
  },
  methods: {
    handleCurrentChange(page) {
      this.page = page;
      this.getData();
    },
    getData() {
      let params = {
        career_fair_id: this.cfId || null,
        limit: this.nums,
        offset: (this.page - 1) * this.nums,
      };
      let ids = [];
      let data = { ...this.filterData };
      if (this.ids && this.ids.length) {
        ids = this.ids;
      }
      if (Object.values(this.filterData).length) {
        delete data.curIndex;
        params = Object.assign(params, data);
      }
      this.loading = true;
      this.$axiosRequest({
        name: this.ajaxApi,
        params: params,
        ids: ids,
      })
        .then((res) => {
          if (res.status === 200) {
            this.data = res.data.results;
            this.total = res.data.count;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  watch: {
    filterData(val, prev) {
      if (val !== prev) {
        this.page = 1;
        this.getData();
      }
    },
  },
  components: {
    CompanyCard,
  },
};
</script>
<style lang="scss">
@import "constants/style/public";

.el-loading-spinner .path {
  stroke: $primaryColor;
}

.company-list {
  .company-list-wrapper {
    min-height: 200px;
    display: flex;
    flex-wrap: wrap;

    .item {
      margin-right: 15px;
      margin-bottom: 15px;

      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
  }

  .pagination {
    text-align: center;
    margin: 30px auto 40px;
  }
}
</style>
<template>
  <div class="company page-container">
    <BannerSwiper height="280px" :swiperList="bannerList" />
    <CompanyFilter :initData="filterData" @change="getFilterData"/>
    <CompanyListWrapper :nums="18" pagination :filterData="filterData" style="margin-top:26px" />
    <!-- <CompanyCard data=""/> -->
  </div>
</template>
<script>//activity
import BannerSwiper from 'components/bannerSwiper.vue'
import CompanyFilter from "components/company/companyFilter"
import CompanyListWrapper from "components/company/companyListWrapper"
// import CompanyCard from "components/companyCard"
// import {bannerList} from "constants/publicData"
export default {
  name:"pageCompany",
  data:()=>({
    bannerList:[{
      img_url:'https://internal-1257093263.cos.ap-shanghai.myqcloud.com/qz-20220127-company.jpg',
      title:"",
      link:""
    }],
    filterData:{
      search_content:"",
    }
  }),
  created(){
    let filterData = {...this.filterData}
    filterData.search_content = this.$route.params.search_content||"" 
    this.filterData = filterData
  },
  methods:{
    getFilterData(data){
      this.filterData = data
    }
  },
  computed:{
    // bannerList:()=>bannerList
  },
  watch:{},
  components: { BannerSwiper,CompanyListWrapper,CompanyFilter },
}
</script>
<style lang="scss">
// @import "constants/style/public";
</style>
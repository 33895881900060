<template>
  <div>
    <a target="_blank" :href="link" class="company-card toLink" :style="width ? 'width:' + width + ';' : ''">
      <div class="company-card-top">
        <div class="img-box">
          <el-image style="width: 70px; height: 70px" :src="data.logo_url || require('assets/mainLogo.png')"
            fit="contain" />
        </div>
        <div class="company-info">
          <div class="title ellipsis">{{ data.company_name || "公司名" }}</div>
          <div class="other-info tags" v-if="sixIndustry && sixIndustryTag">
            <div class="tag" v-for="(item, index) in sixIndustryTag" :key="index">
              {{ item }}
            </div>
          </div>
          <div class="other-info">
            <span>{{ data.company_city || data.city || "公司城市" }}</span>
          </div>

        </div>
      </div>
      <div class="company-card-bottom">
        <div>热招职位</div>
        <div><span class="nums">{{ data.job_num || data.published_job_num || "" }}</span>个</div>
      </div>
    </a>
  </div>
</template>
<script>
export default {
  name: "CompanyCard",
  props: ["data", 'link', 'width', 'isExam','sixIndustry'],
  data: () => ({}),
  methods: {},
  computed: {
    sixIndustryTag() {
      if (this.data && this.data.quzhou_key_industry) {
        return this.data.quzhou_key_industry.slice(0,2)
      } else return []
    }
  },
  watch: {},
};
</script>
<style lang="scss">
@import "constants/style/public";

.company-card {
  display: block;
  width: 390px;
  padding: 18px 20px 0;
  background-color: $defaultColor;
  font-size: $little;
  color: $promptColor;

  &.toLink {
    cursor: pointer;
  }

  &:hover {
    >.company-card-top>.company-info>.title {
      color: $moreColor;
    }
  }

  .company-card-top {
    text-align: left;
    display: flex;
    padding-bottom: 6px;
    border-bottom: 1px solid $borderLineColor;

    .img-box {
      margin-right: 12px;
    }

    .company-info {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;

      .other-info{
        margin-bottom: 8px;
      }

      .tags {
        display: flex;

        .tag {
          padding: 1px 4px;
          background-color: $primaryColor;
          color: #fff;
          margin-right: 2px;
          border-radius: 2px;
        }
      }
    }

    .title {
      max-width: 240px;
      font-size: $large;
      color: $titleColor;
      margin-bottom: 8px;
    }
  }

  .company-card-bottom {
    padding: 8px 0;
    display: flex;
    justify-content: space-between;

    .nums {
      color: $moreColor;
      margin-right: 8px;
    }
  }
}
</style>